@charset "utf-8";

.main{
  .header{
    position:fixed;
    top:0;
    right:0;
    left:0;
  }
}
.header{
  display:flex;
  z-index: 90;
  padding:24px 56px 24px 64px;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
  .logo{
    width:181px;
    height:48px;
    background:url($logo) 50% no-repeat;
    
    a{
      height: 100%;
      display: block;
    }
  }
  &.white{
    .util{
      button{
        &:not(.btn-menu){
          color: #fff;
          background-color: transparent;
          border-color: #fff;
        }
        &.primary{
          color: #111;
          background-color: #fff;
        }
        .btn-menu{
          span{
            background-color: #111;
          }
        }
      }
    }
  }
  .h-mob{
    display: none;
    width: calc(100% - 4.8rem); 
    
    h2{
      flex: 1;
      color: #111;
      font-family: 'pretendard';
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
    }
    .prev{
      position: relative;
      width: 3rem;
      height: 4.8rem;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background: url($ico_arrow_black) 50% no-repeat;
        background-size: contain;
        transform: translateY(-50%) rotate(180deg);
      }
    } 
  }
  .util{
    font-size:0;
    [class*="btn__"]{
      margin-left:16px;
      &:first-child{
        margin-left:0;
      }
    }
    button{
      vertical-align:middle;
      margin-left: 1.6rem;

      // &:not(.btn-menu){
      //   width: auto;
      //   height: 4.5rem;
      //   padding: 1.2rem 3.2rem;
      //   line-height: 1;
      // }
      &:first-child{
        margin-left: 0;   
      }
      &:last-child{
        margin-left: 4rem;
      }
      &.btn{
        &-login{
          &.user{
            position: relative;
            margin-right: 1rem;
            font-family: 'binggrae';
            font-size: 1.8rem;
            font-weight: 700;
            border: 0;
            background-color: transparent;

            span{
              color: #111;
              font-weight: 700;
            }
            &::before{
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              width: 1rem;
              height: 1.5rem;
              background: url($arrow_s_ico) 50% no-repeat;
              transform: translateY(-50%);
            }
            span{
              font-size: 1.8rem;
            }
          }
        }
      }
    }
    &.is-active{
      .primary{
        margin-right:84px;
      }
      .btn-menu{
        position:absolute;
        top:80px;
        right:80px;
        z-index:11;
        span{
          background-color: #111;
          right: 0;
          left: 0; 

          &:nth-of-type(1) {
            transform: translateY(14px) rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            width:auto;
            transform: translateY(-10px) rotate(45deg);
          }
        }
      }
    }
  }
  .btn-menu{
    position:relative;
    width:48px;
    height:48px;
    margin-left:40px;
    
    span {
      display: inline-block;
      position: absolute;
      top:50%;
      right:8px;
      left:8px;
      height: 4px;
      background-color: #111;
      border-radius: 4px;
      transition: transform .3s;
      box-sizing: border-box;
      &:nth-child(1){
        margin-top:-14px;
      }
      &:nth-child(2){
        margin-top:-2px;
      }
      &:nth-child(3){
        margin-top:10px;
        width:20px;
      }
    }
  }
}
.dim{
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: 2;
  background-color:rgba($color: #000000, $alpha: 0.6);
}
.modal-open{
  .header{
    z-index: 2;
  }
  .dim{
    display: block;
  }
}
.nav{
  position: fixed;
  top: 0;
  right: -80rem;
  z-index: 10;
  width: 100%;
  max-width: 80rem;
  padding: 8rem 8rem 8rem 12rem;
  height: 100%;
  transition: all .3s ease-in-out;
  background-color:#fff;
  &:after{
    content:'';
    position:absolute;
    bottom: 5.56rem;
    right: 0;
    width: 40.8rem;
    height: 44.5rem;
    background:url($nav_bg);
  }
  &.is-open{
    right:0;
    transition:all .3s ease-in-out;

    .logo{
      display: block;
    }
    // .btn{
    //   padding: 0 1.25rem;
    //   span{
    //     font-size: 1.8rem;
    //   }
    // }
    // .user-info{
    //   padding-bottom: 2.88rem;
    //   border-bottom: 1px solid #000;

    //   .btn-login{
    //     position: relative;
    //     display: block;
    //     padding: 0 2rem 0 0;
    //     font-family: 'binggrae';
    //     font-size: 3rem;

    //     &::after{
    //       content: '';
    //       position: absolute;
    //       right: 0;
    //       top: 50%;
    //       width: 1rem;
    //       height: 1.5rem;
    //       background: url($arrow_s_ico) 50% no-repeat;
    //       background-size: contain;
    //       transform: translateY(-50%);
    //     }
    //     span{
    //       font-size: 3rem;
    //     }
    //   }
    // }
  }
  &__top{
    margin-top:65px;
    font-size:0;
    
    .btn.sm{
      padding: 0;
    }
    .btn{
      border:none;
      &+.btn{
        margin-left:16px;
      }
    }
  }
  .logo{
    width: 24.3rem;
    height: 8rem;
    background:url($nav_logo) 50% 50% no-repeat;
  }
  .user-info{
    padding-bottom: 2.88rem;
    border-bottom: 1px solid #000;

    .btn-login{
      position: relative;
      display: block;
      padding: 0 2rem 0 0;
      font-family: 'binggrae';
      font-size: 3rem;

      &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1rem;
        height: 1.5rem;
        background: url($arrow_s_ico) 50% no-repeat;
        background-size: contain;
        transform: translateY(-50%);
      }
      span{
        font-size: 3rem;
      }
    }
  }
}
.menu{
  overflow-x:hidden;
  overflow-y:auto;
  position:relative;
  z-index:1;
  margin-top:100px;
  height:calc(100% - 293px);
  
  &.scroll{
    &::-webkit-scrollbar{
      width: 12px;
    } 
    &::-webkit-scrollbar-thumb{
      background: #D9D9D9;
      border: 2px solid #fff;
      border-radius: 12px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
    }
    ::-webkit-scrollbar-track{
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  &__item{
    margin-top:42px;
    &:first-child{
      margin-top:0;
    }
    &.is-active{
      .menu__sub-list{
        display: block;
      }
    }
  }
  &__anchor{
    display:inline-block;
    font-family: 'binggrae';
    font-size: 3.2rem;
    font-weight: 700;
    color:#111;
  }
  &__sub-list{
    display: none;
    overflow:hidden;
    padding-top:24px;
  }
  &__sub-item{
    margin-top:16px;
    &:first-child{
      margin-top:0;
    }
  }
  &__sub-anchor{
    display:inline-block;
    font-family: 'binggrae';
    font-size: 1.8rem;
    font-weight:400;
    color:#111;
    &:hover,&:focus{
      text-decoration:underline;
    }
  }
  &__sub-title{
    margin-top:20px;
    font-size:20px;
    font-weight:800;
    color:#111;
    &:nth-of-type(1){
      margin-top:0;
    }
  }
  &__user-info{
    display: none;
  }
}
.footer{
  padding: 6.25rem 10rem;
  //margin-top: 7.5rem;
  background-color:#111;

  &__logo{
    width:181px;
    height:48px;
    background:url($footer_logo) 50% 50% no-repeat;
  }
  &-link{
    margin-top:48px;
    @include clearfix;
  }
  &-link__item{
    float:left;
    position:relative;
    margin-left:16px;
    padding-left:17px;
    &:after{
      content:'';
      position:absolute;
      top:50%;
      left:0;
      width:1px;
      height:12px;
      margin-top:-6px;
      background-color:rgba($color: #fff, $alpha: 0.2);
    }
    &:first-child{
      margin-left:0;
      padding-left:0;
      &:after{
        display:none;
      }
    }
    &--important{
      .footer-link__anchor{
        font-weight:700;
        color:#fff;
        &:hover,&:focus{
          color:#fff;
        }
      }
    }
  }
  &-link__anchor{
    font-family:'Binggrae';
    font-size: 16px;
    letter-spacing: -0.02em;
    color:#A8A8A8;
    &:hover, &:focus{
      color:#A8A8A8;
    }
  }
  &__copyright{
    margin-top: 2.8rem;
    font-family:'Binggrae';
    font-size:15px;
    line-height:28px;
    letter-spacing:-0.02em;
    color:#666666;
  }
  &-info-list{
    ul{
      overflow: hidden;
      margin-top: .3rem;
      
      li{
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-left: 1.5rem;
        padding-left: 1.5rem; 
        color: #A8A8A8;
        font-family: 'binggrae';
        font-size: 1.6rem;
        letter-spacing: -.03rem;

        &:first-child{
          padding: 0;
          margin: 0;
          
          &::before{
            display: none;
          }
        }
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 12px;
          margin-top: -6px;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      &:nth-child(2){
        li:last-child {
          display: block;
          padding: 0;
          margin: .3rem 0 0;

          &::before{
            display: none;
          }
        }
      }
    }
  }
}
.container{
  min-height:calc(100vh - 9.6rem);
  //margin-top: 96px;
}
.section{
  &:first-child{
    padding-top:96px;
  }
  // padding-right:160px;
  // padding-left:160px;

  .inner{
    max-width: 120rem;
    margin: 1.44rem auto 0;

    &.sm{
      max-width: 64rem;
      margin: 0 auto;
    }
  }
}
.sc-sub-area{
  position: relative;
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-family: 'binggrae';

  &.v1{// 공지사항
    margin-bottom: 7.5rem;
    background: #0f90ff url('../../resources/img/web/sub_tit_bg01.png') 50% no-repeat;
    background-size: cover;
    @include smoothEffect;

    &::after{
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      top: -3%;
      width: 100%;
      height: 34rem;
      background-color: #DEF2FF;
      transform: skewY(-3deg);
    }
  }
  &.v2{// 앱 다운로드
    width: 100%;
    margin-bottom: 6.5rem;  //7.5rem
    background: #0f90ff url('../../resources/img/web/sub_tit_bg02.png') 50% no-repeat;
    background-size: cover;

    &::after{
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      top: 2%;
      width: 100%;
      height: 71rem;  // 40rem/
      background-color: #4EC256;
      transform: skewY(-3deg);
    }
  }
  &.v3{// 정기구독
    width: 100%;
    margin-bottom: 4.7rem;  //7.5rem
    background: #0f90ff url('../../resources/img/web/sub_tit_bg03.png') 50% no-repeat;
    background-size: cover;

    &::after{
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      top: 2%;
      width: 100%;
      height: 50rem;  // 47rem;
      background-color: #4EC256;
      transform: skewY(-3deg);
    }
  }
  &.v4{// 에브리북클럽
    width: 100%;
    margin-bottom: 6rem;  //7.5rem
    background: #0f90ff url('../../resources/img/web/sub_tit_bg04.png') 50% no-repeat;
    background-size: cover;

    &::after{
      content: '';
      z-index: -1;
      display: block;
      position: absolute;
      top: 2%;
      width: 100%;
      height: 73rem;  // 68rem;
      background-color: #FFD231;
      transform: skewY(-3deg);
    }
  }
  .sc-sub-tit{
    font-family: 'binggrae';
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
  }
  p{
    margin-top: 2.6rem;
    text-align: center;
    font-family: 'binggrae';
    font-size: 3.2rem;
    line-height: 1.5;

    b{
      font-family: inherit;
      font-size: inherit;
    }
  }
}
.breadcrumb{
  max-width: 1200px;
  margin: 0 auto 1.5rem;
  
  ul{
    li{
      position: relative;
      display: inline-block;
      padding: 0 1rem;

      &:not(:last-child)::after{
        content: '';
        position: absolute;
        right: -5px;
        top: 50%;
        width: 8px;
        height: 12px;
        background: url($ico_arrow_black) 50% no-repeat;
        background-size: contain;
        transform: translateY(-50%);
      }
    }
  }
  .current{
    a{color: #09A0FF;}   
  }
  .home{
    height: 16px;
    width: 18px;
    background: url($ico_home) 50% no-repeat;
    background-size: contain;
  }
  a{
    display: block;
    font-family: 'pretendard';
    font-size: 1.6rem;
    font-weight: 700;
  }
}
.system-message{
  &-txt{
    position: relative;
    text-align: center;

    &::after{
      content: '';
      display: block;
      width: 16.6rem;
      height: 18.2rem;
      margin: 5rem auto 0;
      background: url('../../resources/img/web/system_error_img.png') 50% no-repeat;
      background-size: contain;
    }
    strong{
      font-family: 'binggrae';
      font-size: 4.2rem;
      font-weight: 700;
      word-break: keep-all;
    }
    p{
      margin-top: 2.4rem;
      letter-spacing: -.04rem;
      font-family: 'binggrae';
      font-size: 2rem;
      line-height: 1.5;
      word-break: keep-all;
    }
    &.b2b{
      p{
        margin-top: 28rem;
        font-family: 'pretendard';
        font-size: 2.4rem;
        font-weight: 700;
      }
      &::after{
        position: absolute;
        top: -22rem;
        left: 50%;
        margin: 0;
        transform: translateX(-50%);
      }
    }
  }
}

.sitemap{
  max-width: 1100px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  margin: 15rem auto 0;

  > li{
    .tit{
      display: block;
      margin-bottom: 8vh;
      color: #111;
      font-family: 'pretendard';
      font-size: 2.3rem;
      font-weight: 700;
    }
    .menu-list{
      > li{
        &:first-child{
          margin-top: 0;
        }
        margin-top: 4vh;
      }
      a{
        color: #000;
        font-family: 'pretendard';
        font-size: 1.8rem;
      }
    }
    .sub{
      &-tit{
        margin-bottom: 4vh;
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
    .sub + .sub{
      margin-top: 4vh;
    }
  }
}






/******************************************************/

@media all and (max-width: 1300px) {
  .breadcrumb{
    max-width: 100%;
    padding: 0 2.8rem;
  }
}

@media all and (max-width: 1024px) {
  
  .breadcrumb{display: none;}
  .header{
    padding: .375rem 1.6rem 0;
    background-color: rgba(255, 255, 255, .7);
  
    >.logo{
      display: none;
    } 
    .util{
      &.is-active{
        .btn-menu{
          span:nth-of-type(1){
            transform: translateY(6px) rotate(-45deg);
          }
        }
      }
      button{
        &:last-child{
          margin-left: 0;
        }
        &:not(.btn-menu){
          display: none;    
        }
      }
    }
    .h-mob{
      display: flex;
      align-items: center;
    }
    .btn-menu{
      margin-left: 0;
    
      span {
        top: 50%;
        right: 11px;
        left: 11px;
        height: 3px;
        border-radius: 3px;

        &:nth-child(1){
          margin-top: -10px;
        }
        &:nth-child(3){
          margin-top: 6px;
        }
      }
    }  
  }
  .sc-sub-area{
    &.v1, &.v2, &.v3, &.v4{
      height: 21rem;
      margin-bottom: 3rem;

      &::after{
        top: -3%;
        width: 100%;
        height: 13rem;
        background-color: #DEF2FF;
        transform: skewY(-3deg);
      }
    }
    .sc-sub-tit{
      font-size: 3.5rem;
      transition: all .3s;

      & + p{
        margin-top: 1.5rem;
        font-size: 2.2rem; 
        transition: all .5s;
      }
    }
  }
  .section{
    padding: 4rem 4rem 0;
  }
  .sitemap{
    max-width: 100%;
    height: auto;
    margin: 6vh 0 15vh;  

    > li{
      .tit{
        margin-bottom: 4rem;
        font-size: 2rem;
      }
      .menu-list{
        > li{
          margin-top: 4vh;
        }
        a{
          font-size: 1.6rem;
        }
      }
      .sub{
        &-tit{
          font-size: 1.6rem;
        }
      }
      .sub + .sub{
        margin-top: 4rem;
      }
    }
  }
}


@media all and (max-width: 960px) {
  
  .container{
    min-height: 100%;
    margin-top: 1.55rem;
    //min-height: calc(100vh - 5.55rem);
    //margin-top: 5.55rem;
  }
  .section{
    padding: 0 2.4rem;
    //margin-top: 5.5rem;

    .inner{
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }
  .footer{
    padding: 3rem;
    margin-top: 0;  
  }
}


@media all and (max-width: 768px) {
  
  .mob{
    .header{
      .util{
        button:not(.btn-menu){
          width: 2.4rem;
          height: 2.4rem;
        }
        &.is-active{
          position: relative;

          .btn-menu{
            top: 0;
            right: 0;
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
    .nav{
      right: -100%;
      max-width: 100%;
      padding: 2.4rem 0;
      height: 100%;

      &:after{
        display: none;
      }
      .btn:not(.user){
        display: none;
      }
      &.is-open{
        right:0;
        transition:all .3s ease-in-out;
    
        .btn.sm{
          display: block;
          min-width: auto;
          height: 3rem;
          padding: 0 1.25rem;
          font-size: 1.5rem;
          line-height: 1;
          
          span{
            font-size: 1.2rem;
          }
          &.user{
            position: relative;
            padding: 0 2rem 0 0;
            font-family: 'binggrae';
            font-size: 2.2rem;

            span{
              font-family: inherit;
              font-size: inherit;
            }

            &::after{
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              width: .8rem;
              height: 1.5rem;
              background: url($arrow_s_ico) no-repeat 50%;
              background-size: contain;
              transform: translateY(-50%);
            }
          }
        }
        .user-info{
          padding-bottom: 2.88rem;
          border-bottom: 1px solid #000;
    
          .btn-login{
            position: relative;
            display: block;
            padding: 0 2rem 0 0;
            font-family: 'binggrae';
            font-size: 3rem;
    
            &::after{
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              width: 1rem;
              height: 1.5rem;
              background: url($arrow_s_ico) 50% no-repeat;
              background-size: contain;
              transform: translateY(-50%);
            }
            span{
              font-size: 3rem;
            }
          }
        }
      }
      &__top{
        display: flex;
        margin-top: 0;
        position: absolute;
        right: 6.5rem;
        top: 2.5rem;

        .btn{
          border: none;
          &+.btn{
            margin-left: 16px;
          }
        }
      }
      .logo{
        @include logoMob;
      }
    }
    .menu{
      height: 100%;
      margin-top: 5rem;

      &__item{
        margin-top: 3rem;
        padding: 0 2.4rem;
      }
      &__anchor{
        font-size: 2.2rem;
      }
      &__sub-item{
        &:first-child{
          margin-top: 0;
        }
        margin-top: 1rem;
      }
      &__sub-list{
        margin-top: 1.7rem;
        padding-top: 2rem;
        border-top: 1px solid #000;
      }
      &__sub-anchor{
        font-size: 1.5rem;
        line-height: 2;
      }
      &__sub-title{
        margin-top: 1.8rem;
        font-size: 1.8rem;
      }
      &__user-info{
        display: block;
        padding: 3.4rem 0 3rem 2.4rem;
        margin: 0 2.4rem 5rem 2.4rem;
        border: 1px solid #111;
        border-radius: 2rem;
        box-shadow: 9px 7px 20px 0px rgba(0, 0, 0, .3);
        
        .btn-login{
          &.user{
            position: relative;
            padding: 0 2rem 0 0;
            font-family: 'binggrae';
            font-size: 2.2rem;
            font-weight: 700;
            color: #111;
            border: 0;
            background-color: transparent;
            border-radius: 0;

            &::after{
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              width: 1rem;
              height: 1.5rem;
              background: url($arrow_s_ico) 50% no-repeat;
              background-size: contain;
              transform: translateY(-50%);
            }
            span{
              color: #111;
              font-family: inherit;
              font-size: inherit;
              font-weight: inherit;
              line-height: 1.3;
            }
          }
        }
        p{
          margin-bottom: 1rem;
          font-family: 'binggrae';
          font-size: 1.2rem;
        }
      }
    }
  }
  .system-message{
    &-txt{
      &::after{
        width: 100%;
        height: 14rem;
      }
      strong{
        font-size: 3.2rem;
      }
      p{
        font-size: 1.4rem;
      }
      &.b2b{
        p{
          margin-top: 24rem;
          font-size: 2.4rem;
        }
        &::after{
          top: -18rem;
        }
      }
    }
  }
  .sc-sub-area{
    display: none;
  }
  .footer{
    &-link__anchor{
      font-family:'Binggrae';
      font-size: 1.4rem;
      letter-spacing: -0.03.2rem;
      color:#A8A8A8;
      &:hover, &:focus{
        color:#A8A8A8;
      }
    }
    &-link__item{
      margin-left: .7rem;
      padding-left: .7rem;
    }
    &-link{
      margin-top: 1.5rem;
    }
    &-info-list{
      ul{
        > li{
          display: block;
          font-size: 1.4rem;
          padding-left: 0;
          margin: .6rem 0 0;
  
          &::before{
            display: none;
          }
          &:first-child{
            padding-left: 0;
            margin: .6rem 0 0;
          }
        }
        &:nth-child(2){
          li:last-child {
            margin: .6rem 0 0;
          }
        }
      }
    }
  }
  .section{
    .inner{
      &.sm{
        max-width: 100%;

        .btn-wrap{
          &.fx-center{
            margin: 4rem 0;
            justify-content: center;

            button{
              min-width: 15rem;
              padding: 0 2rem;
            }
            button + button{
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
  .sitemap{
    height: auto;
    flex-wrap: wrap;
    
    > li{
      width: 50%;
      margin-bottom: 5vh;

      .tit{
        margin-bottom: 2vh;
      }
      .menu-list{
        > li{
          margin-top: 2vh;
        }
      }
      .sub{
        &-tit{
          margin-bottom: 2vh;
        }
      }
      .sub + .sub{
        margin-top: 2vh;
      }
    }
  }

}



@media all and (max-width: 430px) {

  // .header{
  //   background: none;
    
  //   .h-mob{display: none;}
  // }
  .header{
    .util{
      .btn-menu{
        width: 2.4rem;
        height: 2.4rem;

        span{
          right: 0;
          left: 0;
        }
        span:nth-child(3){
          width: 70%;
        }
      }
    }
  }
  .footer{
    &-info-list{
      ul{
        li{
          float: none;
          margin: .5rem 0 0 0;
          padding: 0;
  
          &:first-child{
            margin: .5rem 0 0 0;
          }
          &::before{
            display: none;
          }
        }
      }
    }
  }
}

